input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.profileDetails {
  padding: 20px;

  padding-bottom: 0;
  .personalDetail {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 20px;
    gap: 15px;
    .img_container {
      width: 70px;
      height: 100px;
      border-radius: 8px;
      overflow: hidden;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      &:hover {
        transform: translateY(-2px);
      }
    }
    .fingerDetails {
      width: 100%;
      .form-group {
        width: 70%;
        margin-bottom: 0;
        input {
          height: 30px;
          font-size: 13px;
          font-weight: 600;
          color: #3d3c3c;
          margin-bottom: 10px;
        }
        select {
          height: 40px;
          font-size: 13px;
          font-weight: 600;
          color: #3d3c3c;
          margin-bottom: 10px;
        }
      }
      p {
        margin-bottom: 6px;
        font-size: 13px;
        font-weight: 500;
        span {
          font-weight: 600;
          color: #3d3c3c;
        }
      }
      .name {
        margin-bottom: 6px;
        display: flex;
        align-items: center;
        i {
          font-size: 15px;
          color: rgb(0, 209, 0);
          cursor: pointer;
        }
      }
    }
  }
}
.report {
  h3 {
    background-color: #f78400;
  }
  .card-body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .img_container {
      width: 100%;
      height: 100%;
      max-width: 1200px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .pdf {
      max-width: 990px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
    }
    .pageToCapture {
      position: relative;
      margin-bottom: 5px;
    }
    .img-fluid {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .btn-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .absolute {
      position: absolute;
      color: #ffffff;
      font-weight: 600;
      font-size: 12px;
      z-index: 2;
    }
    .l1 {
      left: 23%;
      top: 53%;
      z-index: 2;
    }
    .l2 {
      left: 27%;
      top: 59.5%;
      z-index: 2;
    }
    .l3 {
      left: 63%;
      top: 59.5%;
      z-index: 2;
    }
    .l4 {
      left: 27%;
      top: 66%;
      z-index: 2;
    }
    .l5 {
      left: 60%;
      top: 66%;
      z-index: 2;
    }
    .l6 {
      left: 24%;
      top: 73%;
      z-index: 2;
    }
    .l7 {
      left: 14%;
      top: 43%;
      z-index: 2;
      color: #000;
    }
    .l8 {
      right: 3%;
      top: 43%;
    }
    .l9 {
      right: 44%;
      top: 46.2%;
    }
    .l10 {
      left: 42%;
      top: 64.5%;
    }
    .l11 {
      right: 40.5%;
      top: 64.5%;
    }
    .l12 {
      left: 48.7%;
      top: 33%;
    }
    .l13 {
      left: 32%;
      top: 51.5%;
    }
    .l14 {
      right: 33%;
      top: 51.5%;
    }
    .l15 {
      left: 49.2%;
      top: 35%;
    }
    .l16 {
      left: 23.5%;
      top: 53%;
    }
    .l17 {
      left: 49%;
      top: 52%;
    }
    .l18 {
      right: 23%;
      top: 53.5%;
    }
    .l19 {
      left: 49%;
      top: 57%;
    }
    .l20 {
      left: 49%;
      top: 34.7%;
    }
    .l21 {
      left: 23.5%;
      top: 52.5%;
    }
    .l22 {
      right: 22.5%;
      top: 51%;
    }
    .l23 {
      left: 49%;
      top: 34.5%;
    }
    .l24 {
      left: 34%;
      top: 52.5%;
    }
    .l25 {
      right: 34%;
      top: 51%;
    }
    .l26 {
      left: 49%;
      top: 34.5%;
    }
    .l27 {
      left: 34%;
      top: 51.5%;
    }
    .l28 {
      right: 34%;
      top: 53%;
    }
    .l29 {
      left: 49%;
      top: 34%;
    }
    .l30 {
      left: 49%;
      top: 52%;
    }
    .l31 {
      left: 34.3%;
      top: 53.5%;
    }
    .l32 {
      right: 30%;
      top: 26.5%;
    }
    .l33 {
      right: 30%;
      top: 31.5%;
    }
    .l34 {
      right: 30%;
      top: 36.2%;
    }
    .l35 {
      right: 30%;
      top: 40.9%;
    }
    .l36 {
      right: 30%;
      top: 45.7%;
    }
    .l37 {
      left: 44%;
      top: 20%;
    }
    .l38 {
      right: 32%;
      top: 20%;
    }
    .l39 {
      left: 44%;
      top: 54.5%;
    }
    .l40 {
      right: 33%;
      top: 54.5%;
    }
    .l41 {
      left: 41%;
      bottom: 46%;
    }
    .l42 {
      right: 19%;
      bottom: 46%;
    }
    .l43 {
      left: 41%;
      bottom: 24.5%;
    }
    .l44 {
      right: 18.8%;
      bottom: 24.3%;
    }
    .l45 {
      left: 27.5%;
      bottom: 23.6%;
    }
    .l46 {
      left: 24.5%;
      bottom: 25.5%;
    }
    .l47 {
      left: 29.5%;
      bottom: 23.6%;
    }
    .l48 {
      left: 36%;
      top: 27.5%;
      color: #000;
    }
    .l49 {
      right: 34%;
      top: 27.5%;
    }
    .l50 {
      right: 41%;
      top: 36%;
    }
    .l51 {
      right: 15%;
      top: 36%;
    }
    .l52 {
      right: 39%;
      top: 52.5%;
    }
    .l53 {
      right: 16%;
      top: 52.5%;
    }
    .l54 {
      right: 39%;
      top: 69.5%;
    }
    .l55 {
      right: 16%;
      top: 69.5%;
    }
    .l56 {
      left: 38%;
      top: 23%;
      font-weight: 700;
      color: #000;
    }
    .l57 {
      right: 19%;
      top: 23%;
      font-weight: 700;
      color: #000;
    }
    .l58 {
      left: 38.7%;
      bottom: 34.5%;
      font-weight: 700;
      color: #000;
      font-size: 15px;
    }
    .l59 {
      right: 20.2%;
      bottom: 33%;
    }
    .l60 {
      left: 21%;
      top: 29.8%;
    }
    .l61 {
      right: 43%;
      top: 29.8%;
    }
    .l62 {
      right: 16%;
      top: 29.8%;
    }
    .l63 {
      left: 33.5%;
      top: 28.5%;
      font-weight: 700;
      color: #000;
      font-size: 20px;
    }
    .l64 {
      right: 14%;
      top: 28.5%;
      font-weight: 700;
      color: #000;
      font-size: 20px;
    }
    .l65 {
      left: 33.5%;
      top: 36%;
      font-weight: 700;
      color: #000;
      font-size: 20px;
    }
    .l66 {
      right: 14%;
      top: 36%;
      font-weight: 700;
      color: #000;
      font-size: 20px;
    }
    .l67 {
      left: 33.5%;
      top: 43.4%;
      font-weight: 700;
      color: #000;
      font-size: 20px;
    }
    .l68 {
      right: 14%;
      top: 43.4%;
      font-weight: 700;
      color: #000;
      font-size: 20px;
    }
    .l69 {
      left: 33.5%;
      top: 51%;
      font-weight: 700;
      color: #000;
      font-size: 20px;
    }
    .l70 {
      right: 14%;
      top: 51%;
      font-weight: 700;
      color: #000;
      font-size: 20px;
    }
    .l71 {
      left: 33.5%;
      bottom: 27.8%;
      font-size: 20px;
    }
    .l72 {
      right: 14%;
      bottom: 27.8%;
      font-size: 20px;
    }
    .l73 {
      left: 33.5%;
      bottom: 20.7%;
      font-size: 20px;
    }
    .l74 {
      right: 14%;
      bottom: 20.7%;
      font-size: 20px;
    }
    .l75 {
      left: 33.5%;
      bottom: 13%;
      font-size: 20px;
    }
    .l76 {
      right: 14%;
      bottom: 13%;
      font-size: 20px;
    }
    .l77 {
      left: 33.5%;
      bottom: 5%;
      font-size: 20px;
    }
    .l78 {
      right: 14%;
      bottom: 5%;
      font-size: 20px;
    }
    .l79 {
      left: 33.5%;
      top: 59%;
      font-weight: 700;
      color: #000;
      font-size: 20px;
    }
    .l80 {
      right: 14%;
      top: 59%;
      font-weight: 700;
      color: #000;
      font-size: 20px;
    }
    .l81 {
      left: 33.5%;
      top: 30.5%;
      font-weight: 700;
      color: #000;
      font-size: 20px;
    }
    .l82 {
      right: 14%;
      top: 30.5%;
      font-weight: 700;
      color: #000;
      font-size: 20px;
    }
    .l83 {
      left: 33.5%;
      top: 38%;
      font-weight: 700;
      color: #000;
      font-size: 20px;
    }
    .l84 {
      right: 14%;
      top: 38%;
      font-weight: 700;
      color: #000;
      font-size: 20px;
    }
    .l85 {
      left: 33.5%;
      bottom: 41%;
      font-size: 20px;
    }
    .l86 {
      right: 14%;
      bottom: 41%;
      font-size: 20px;
    }
    .l87 {
      left: 33.5%;
      bottom: 33.4%;
      font-size: 20px;
    }
    .l88 {
      right: 14%;
      bottom: 33.4%;
      font-size: 20px;
    }
    .l89 {
      left: 33.5%;
      bottom: 25.8%;
      font-size: 20px;
    }
    .l90 {
      right: 14%;
      bottom: 25.8%;
      font-size: 20px;
    }
    .l91 {
      left: 33.5%;
      bottom: 18%;
      font-size: 20px;
    }
    .l92 {
      right: 14%;
      bottom: 18%;
      font-size: 20px;
    }
    .l93 {
      left: 33.5%;
      bottom: 10.5%;
      font-size: 20px;
    }
    .l94 {
      right: 14%;
      bottom: 10.5%;
      font-size: 20px;
    }
    .l95 {
      left: 33.5%;
      top: 45.5%;
      font-weight: 700;
      color: #000;
      font-size: 20px;
    }
    .l96 {
      right: 14%;
      top: 45.5%;
      font-weight: 700;
      color: #000;
      font-size: 20px;
    }
    .l97 {
      left: 33.5%;
      bottom: 32%;
      font-size: 20px;
    }
    .l98 {
      right: 14%;
      bottom: 32%;
      font-size: 20px;
    }
    .l99 {
      left: 33.5%;
      bottom: 24.5%;
      font-size: 20px;
    }
    .l100 {
      right: 14%;
      bottom: 24.5%;
      font-size: 20px;
    }
    .l101 {
      left: 33.5%;
      bottom: 17%;
      font-size: 20px;
    }
    .l102 {
      right: 14%;
      bottom: 17%;
      font-size: 20px;
    }
    .l103 {
      left: 33.5%;
      bottom: 9.5%;
      font-size: 20px;
    }
    .l104 {
      right: 14%;
      bottom: 9.5%;
      font-size: 20px;
    }
    .l105 {
      left: 33.5%;
      top: 44%;
      font-size: 20px;
    }
    .l106 {
      right: 14%;
      top: 44%;
      font-size: 20px;
    }
    .l107 {
      left: 33.5%;
      bottom: 37%;
      font-weight: 700;
      color: #000;
      font-size: 20px;
    }
    .l108 {
      right: 14%;
      bottom: 37%;
      font-weight: 700;
      color: #000;
      font-size: 20px;
    }
    .l109 {
      left: 33.5%;
      bottom: 29.5%;
      font-weight: 700;
      color: #000;
      font-size: 20px;
    }
    .l110 {
      right: 14%;
      bottom: 29.5%;
      font-weight: 700;
      color: #000;
      font-size: 20px;
    }
    .l111 {
      left: 33.5%;
      bottom: 22%;
      font-weight: 700;
      color: #000;
      font-size: 20px;
    }
    .l112 {
      right: 14%;
      bottom: 22%;
      font-weight: 700;
      color: #000;
      font-size: 20px;
    }
    .l113 {
      left: 33.5%;
      bottom: 14.3%;
      font-weight: 700;
      color: #000;
      font-size: 20px;
    }
    .l114 {
      right: 14%;
      bottom: 14.3%;
      font-weight: 700;
      color: #000;
      font-size: 20px;
    }
    .l115 {
      left: 33.5%;
      bottom: 6.5%;
      font-weight: 700;
      color: #000;
      font-size: 20px;
    }
    .l116 {
      right: 14%;
      bottom: 6.5%;
      font-weight: 700;
      color: #000;
      font-size: 20px;
    }
    .l117 {
      left: 33.5%;
      top: 28.5%;
      font-size: 20px;
    }
    .l118 {
      right: 14%;
      top: 28.5%;
      font-size: 20px;
    }
    .l119 {
      left: 33.5%;
      top: 36%;
      font-size: 20px;
    }
    .l120 {
      right: 14%;
      top: 36%;
      font-size: 20px;
    }
    .l121 {
      left: 34%;
      top: 37.8%;
    }
    .l122 {
      right: 35%;
      top: 38%;
    }
    .l123 {
      left: 34%;
      top: 45.3%;
    }
    .l124 {
      right: 35%;
      top: 45%;
    }
    .l125 {
      left: 34%;
      top: 53%;
    }
    .l126 {
      right: 35%;
      top: 53%;
    }
    .l127 {
      left: 34%;
      top: 60.8%;
    }
    .l128 {
      right: 35%;
      top: 60.8%;
    }
    .l129 {
      left: 17%;
      top: 75.5%;
    }
    .l130 {
      right: 60%;
      top: 75.5%;
    }
    .l131 {
      right: 32%;
      top: 75.5%;
    }
    .l132 {
      right: 19.5%;
      top: 75.5%;
    }
    .l133 {
      right: 61.5%;
      top: 41%;
    }
    .l134 {
      right: 61.5%;
      top: 48%;
    }
    .l135 {
      right: 61.5%;
      top: 56%;
    }
    .l136 {
      right: 80%;
      top: 71%;
    }
    .l137 {
      right: 54%;
      top: 71%;
    }
    .l138 {
      right: 35.5%;
      top: 71%;
    }
    .l139 {
      right: 18.5%;
      top: 71%;
    }
    .l140 {
      right: 4.5%;
      top: 71%;
    }
    .l141 {
      right: 82%;
      top: 78.8%;
    }
    .l142 {
      right: 55%;
      top: 78.8%;
    }
    .l143 {
      right: 82%;
      top: 87%;
    }
    .l144 {
      right: 71%;
      top: 87%;
    }
    .l145 {
      right: 71%;
      top: 87%;
    }
    .l146 {
      right: 34.5%;
      top: 78.8%;
    }
    .l147 {
      right: 6.5%;
      top: 78.8%;
    }
    .l148 {
      right: 34.5%;
      top: 87%;
    }
    .l149 {
      right: 22.7%;
      top: 87%;
    }
    .l150 {
      right: 6.5%;
      top: 87%;
    }
    .c1 {
      right: 30px;
      bottom: 50px;
    }
    .c2 {
      left: 30px;
      bottom: 50px;
    }
    .c3 {
      left: 50%;
      transform: translateX(-50%);
      top: 20%;
    }
    .c4 {
      right: 15%;
      top: 38%;
    }
    .c5 {
      left: 50%;
      transform: translateX(-50%);
      top: 30%;
    }
    .c6 {
      left: 50%;
      transform: translateX(-50%);
      bottom: 30px;
    }
    .pie-chart {
      width: 200px;
      height: 200px;
      border-radius: 50%;
      background: conic-gradient(
        #2e9db8 0% 25%,
        #dc1c28 25% 60%,
        #e9601a 60% 100%
      );
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .segment {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 10px;
    }

    .percentage {
      font-size: 24px;
      font-weight: bold;
      color: #fff;
    }

    .label {
      font-size: 16px;
      color: #333;
    }
  }
}
.card .table td {
  font-size: 13px;
  padding: 5px 10px;
}
table.personlDetails-table {
  border: 1px solid #1d52ff;
  width: 100%;
  th {
    background-color: #1d52ff;
    padding: 6px 10px;
    color: #ffffff;
  }
  td {
    padding: 6px 10px;
    font-size: 13px;
    border: 1px dotted #ccc;
  }
}
.update-btn {
  .spinner-border {
    width: 1rem;
    height: 1rem;
  }
}
.ant-pagination-next,
.ant-pagination-prev {
  .anticon {
    color: #003bfe;
  }
}
.status-btn {
  width: 103px;
}
.jsontoxlsx {
  .ant-modal-content {
    background-color: #fff;
  }
  .ant-modal-title {
    background-color: #fff;
  }
}
